@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #d1d5db;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: #f3f4f6;
  border-radius: 4px;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #d1d5db #f3f4f6;
}

.max-w-96-important {
  max-width: 24rem !important;
}

.fc .fc-timegrid-slot {
  border-bottom: 0px;
  height: 2.5em;
}
